(function ($) {
  Drupal.behaviors.splitMediaWithTextV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var $template = $('.js-split-media-with-text-v1', context);

      $template.each(function () {
        var $contentCarousel = $('.js-content-carousel', $(this));
        var $contentCarouselItem = $('.js-content-carousel-image', $contentCarousel);
        var $carouselDots = $('.js-carousel-dots', $(this));
        var $carouselDotsItem = $('.js-carousel-dots-item', $carouselDots);

        // Add class active when click on dot
        $carouselDotsItem.once().on('click', function (e) {
          e.preventDefault();
          var positionDot = $(this).index() + 1;
          var positionSlider = positionDot * $contentCarouselItem.width() - $contentCarouselItem.width();

          $contentCarousel.scrollLeft(positionSlider);
        });

        // Add class active on swipe the carousel
        $contentCarousel.scroll(
          _.debounce(function () {
            var currentPosition = $(this).scrollLeft();
            var positionSlider = 0;

            $contentCarouselItem.each(function () {
              var sliderId = $(this).attr('data-slide-id');

              if (currentPosition === positionSlider) {
                $carouselDots
                  .find('[data-slide-id="' + sliderId + '"]')
                  .addClass('active')
                  .siblings()
                  .removeClass('active');
              }
              positionSlider = positionSlider + $contentCarouselItem.width();
            });
          }, 100)
        );
      });
    }
  };
})(jQuery);
